import {app} from '../app';
import PouchDB from 'pouchdb-browser';
import PouchDBAuth from 'pouchdb-authentication';
PouchDB.plugin(PouchDBAuth);

var uuid = require("uuid");

const couch_address = 'https://gestioneintegrataservizi.it:3395/';
const report_url = 'https://gestioneintegrataservizi.it:3391/report/';
// const report_url = 'http://192.168.118.60:3030/report/';

var userDB = new PouchDB(couch_address, {skip_setup:true});

export function getReport(self, time_key, callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(self.$app.data.user.username, {
			metadata: {
					tmp_key: tmpKey
			}
	}, (err, res) => {
			if(err) {
				console.log(err);
					// Error
					return callback(err, undefined);
			}
			self.$app.request({
					url: report_url+time_key,
					method: 'POST',
					dataType: 'json',
					headers: {
							'X-Access-Personal-Key': self.$app.data.user.username+':'+tmpKey
					},
					data: {
						start: self.time_range[0].toISOString(),
						end: self.time_range[1].toISOString()
					},
					error: (xhr, status, message) => {
						console.log(xhr);
						return callback(xhr, undefined);
					},
					success: (data, status, xhr) => {
						return callback(undefined, data);
					}
			});
	});
}
