import {isUserAuthenticated, isUserAuthenticatedAndAdmin} from './modules/couchdb-auth-module';

import {getAllPdi,
	getDamagesWithLabels, getAllDamagesWithLabelsByTimeRange,
	getDamageWithLabels,
	getAllActivitiesWithDamagesByTimeRange, getActivitiesWithDamagesByTimeRange} from './modules/couchdb-pdi-module';

import {getAllUsers, getUser} from './modules/couchdb-user-module';

import {app} from './app';

import ActivityPage from '../pages/activity-page.f7.html';
import DamagePage from '../pages/damage-page.f7.html';
import DamageDetailPage from '../pages/damage-detail-page.f7.html';
import RoutePage from '../pages/route-page.f7.html';
import EditRoutePage from '../pages/edit-route-page.f7.html';
import UserPage from '../pages/user-page.f7.html';
import EditUserPage from '../pages/edit-user-page.f7.html';
import LoginPage from '../pages/login-page.f7.html';
import NotFoundPage from '../pages/404.f7.html';
import PdiPage from '../pages/pdi-page.f7.html';
import ReportPage from '../pages/report.f7.html';

var routes = [
	{
		name: 'login',
		path: '/login/',
		component: LoginPage
	},
	{
		path: '/',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				getAllPdi((error, response) => {
					if (error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject()
						});
					} else {
						this.app.preloader.hide();
						return resolve(
							{
								component: PdiPage
							},
							{
								context: {
									routes: response
								}
							}
						);
					}
				});
			});
		},
		alias: '/pdi-page/'
	},
	{
		path: '/activity-page/',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				var now = new Date();
				var end_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
				getAllActivitiesWithDamagesByTimeRange(now, end_date, (error, response) => {
					if (error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject()
						});
					} else {
						this.app.preloader.hide();
						return resolve(
							{
								component: ActivityPage
							},
							{
								context: {
									activities: response,
									last_time: end_date,
									linkBack: false
								}
							}
						);
					}
				});
			});
		}
	},
	{
		path: '/route-detail-page/:routeId/:routeName',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				const route_id = routeTo.params.routeId;
				const route_name = routeTo.params.routeName;
				var now = new Date();
				var end_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() -7 );
				getActivitiesWithDamagesByTimeRange(route_id, now, end_date, (error, response) => {
					if (error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject()
						});
					} else {
						this.app.preloader.hide();
						return resolve(
							{
								component: ActivityPage
							},
							{
								context: {
									activities: response,
									route_name: route_name,
									route_id: route_id,
									last_time: end_date,
									linkBack: true
								}
							}
						);
					}
				});
			});
		}
	},
	{
		path: '/damage-page/',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				var now = new Date();
				var end_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
				getAllDamagesWithLabelsByTimeRange(now, end_date, (error, response) => {
					if(error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject()
						});
					} else {
						this.app.preloader.hide();
						return resolve(
							{
								component: DamagePage
							},
							{
								context: {
									damages: response,
									last_time: end_date,
									linkBack: false
								}
							}
						);
					}
				});
			});
		}
	},
	{
		path: '/activity-detail-page/:activityId/:activityName',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				var activity_id = routeTo.params.activityId;
				var activity_name = routeTo.params.activityName;
				getDamagesWithLabels(activity_id, (error, damages) => {
					if(error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject()
						});
					} else {
						this.app.preloader.hide();
						return resolve(
							{
								component: DamagePage
							},
							{
								context: {
									damages: damages,
									linkBack: true,
									activity_name: activity_name
								}
							}
						);
					}
				});

			});
		}
	},
	{
		path: '/damage-detail-page/:id',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				getDamageWithLabels(routeTo.params.id, (error, damage) => {
					if(error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject()
						});
					} else {

						this.app.preloader.hide();
						return resolve(
							{
								component: DamageDetailPage
							},
							{
								context: {
									damage: damage
								}
							}
						);
					}
				});
			});
		}
	},
	{
		path: '/route-page/id/:id',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				if (!isAdmin) {
					this.app.preloader.hide();
					return reject();
				}
				this.app.preloader.hide();
				return resolve({
					component: RoutePage
				});
			});
		},
		alias: '/route-page/'
	},
	{
		path: '/user-page/',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				if (!isAdmin) {
					this.app.preloader.hide();
					return reject();
				}
				getAllUsers((error, response) => {
					if(error) {
						this.app.preloader.hide();
						this.app.dialog.alert("Errore di rete!", ()=> {
							return reject();
						});
					}
					this.app.preloader.hide();
					return resolve(
						{
							component: UserPage
						},
						{
							context: {
								users: response.users
							}
						}
					);
				});

			});
		}
	},
	{
		path: '/edit-user-page/id/:id',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				if (!isAdmin) {
					this.app.preloader.hide();
					return reject();
				}
				var user_id = routeTo.params.id;
				if (user_id) {
					getUser(user_id, (error, response) => {
						if(error) {
							this.app.preloader.hide();
							this.app.dialog.alert("Errore di rete!", ()=> {
								return reject();
							});
						} else {
							this.app.preloader.hide();
							return resolve(
								{
									component: EditUserPage
								},
								{
									context: {
										new: false,
										user: response.user
									}
								}
							);
						}
					});
				} else {
					this.app.preloader.hide();
					return resolve(
						{
							component: EditUserPage
						},
						{
							context: {
								new: true
							}
						}
					);
				}
			});
		},
		alias: '/new-user/'
	},
	{
		path: '/edit-route-page/id/:id',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				if (!isAdmin) {
					this.app.preloader.hide();
					return reject();
				}
				this.app.preloader.hide();
				return resolve({
					component: EditRoutePage
				});
			});
		}
	},
	{
		path: '/report/',
		async(routeTo, routeFrom, resolve, reject) {
			this.app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (!isAuth) {
					this.app.preloader.hide();
					return resolve({
						component: LoginPage
					});
				}
				this.app.preloader.hide();
				return resolve({
					component: ReportPage
				});
			});
		}
	},
	{
		path: '(.*)',
		component: NotFoundPage,
	},
];

export default routes;
