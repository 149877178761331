import {getReport} from '../modules/report-module.js';

export async function getWeeklyReport(self){
	self.$app.preloader.show();
	// const file_url = 'http://192.168.118.60:3030/report/getFile/';
	const file_url = 'https://gestioneintegrataservizi.it:3391/report/getFile/';
	getReport(self, 'week', (error, response) => {
		if(error) {
			self.$app.preloader.hide();
			self.$app.dialog.alert('Errore! Riprova più tardi.', () => {
				self.$router.refreshPage();
			});
			return;
		}
		var file = response.file;
		var filename = response.filename;
		self.$app.preloader.hide();
	 	window.open(file_url+file+'/'+filename, '_blank');
	});
}
