
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { userLogout } from './js/modules/couchdb-auth-module';
export default {
  methods: {
    logout() {
      var self = this;
      this.$app.dialog.confirm('Vuoi disconneterti?', () => {
        window.sessionStorage.removeItem('userdata');
        userLogout(self);
      });
    }

  },
  on: {
    pageInit(page) {
      this.$setState(this.$app.data.user);
      this.$app.panel.close('.panel', false);
    }

  },
  id: '754f77e5c6',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Left panel with cover effect --><div class="panel panel-left panel-cover panel-init" data-visible-breakpoint=960 data-swipe=true><div class="view view-init" data-view=left data-name=left><div class=page><div class="navbar theme-dark"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="title main-left-title">DrEAM</div><div class=right><img style="height: 45px; padding-right: 7px;" src=../static/icons/icon.png></div></div></div><div class="subnavbar panel-subnavbar"><div class="subnavbar-inner gis-logo-div-navbar"><a class=external href=https://www.gestioneintegrataservizi.it/home/ target=_blank><img class=gis-logo-img src=static/icons/BNorfano.png></a></div></div><div class="toolbar toolbar-bottom theme-dark"><div class=toolbar-inner><div class=block></div><div class=right><div class=arpsoft><span>Powered by</span></div><a class=external target=_blank href=https://www.arpsoft.it/en/ ><img class=arpsoft src=../static/icons/Arpsoft_Logo_White.png></a></div></div><div class=lbte_ver><span class=text-color-black>ver ';
      r += c(ctx_1.$app.data.version.number, ctx_1);
      r += '</span></div></div>';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.$app.data.user).length !== 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="page-content panel-content-list"><div class="block-title text-color-blue">HOME</div><div class="list links-list"><ul><li><a href=/ data-view=.view-main class=panel-close>Punti Di Interesse</a></li><li><a href=/activity-page/ data-view=.view-main class=panel-close>Attività</a></li><li><a href=/damage-page/ data-view=.view-main class=panel-close>Criticità</a></li></ul></div><!-- TODO: Chamge in includes(\'Admin\')\n					--> ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.$app.data.user.roles.includes("Admin")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="block-title text-color-blue">Amministrazione</div><div class="list links-list"><ul><li><a href=/route-page/ data-view=.view-main class=panel-close>Itinerari</a></li><li><a href=/user-page/ data-view=.view-main class=panel-close>Utenti</a></li></ul></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class="block-title text-color-blue">Reportistica</div><div class="list links-list"><ul><li><a href=/report/ data-view=.view-main class=panel-close>Ottieni Report</a></li></ul></div><!--\n				--><div class=block-title><strong>';
          r += c(ctx_2.$app.data.user.username, ctx_2);
          r += '</strong></div><div class=block-title><a href=# data-view=.view-main @click=logout()><b>Log Out</b></a></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div><!-- Your main view, should have "view-main" class --><div class="view view-main view-init safe-areas theme-dark" data-url=/ data-push-state=false></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    