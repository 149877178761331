
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { saveUser, removeUser } from '../js/utils/edit-user-util';
export default {
  methods: {
    save() {
      saveUser(this);
    },

    remove() {
      removeUser(this);
    }

  },
  on: {
    pageInit: function (page) {
      if (!this.new) {
        this.$$(`#role-selector option[value="${this.user.roles[0]}"]`).prop('selected', true);
      }
    }
  },
  id: 'ba56735f33',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=edit-user-page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=/user-page/ class=link><i class="icon f7-icons">arrow_left</i></a></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.new, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=title>Aggiungi Utente</div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=title>Modifica ';
          r += c(ctx_2.user.name, ctx_2);
          r += '</div><div class=right><a href=# class="link icon-only tooltip-init" @click=remove() data-tooltip="Rimuovi ';
          r += c(ctx_2.user.name, ctx_2);
          r += '"><i class="icon f7-icons">person_badge_minus</i></a></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=page-content><form class=list id=user-form><ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.new, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Username *</div><div id=username class=item-input-wrap><input type=text name=username placeholder="Inserisci username (non potrà essere più cambiato)"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Password *</div><div id=password class=item-input-wrap><input type=password name=password placeholder="Imposta password"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Conferma password *</div><div id=passwordConfirm class=item-input-wrap><input type=password name=passwordConfirm placeholder="Conferma password"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nome *</div><div id=firstname class=item-input-wrap><input type=text name=firstname placeholder=Nome></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Cognome *</div><div id=familyname class=item-input-wrap><input type=text name=familyname placeholder=Cognome></div></div></div></li>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Username</div><div id=username class=item-input-wrap><input type=text name=username value="';
          r += Template7Helpers.js.call(ctx_2, 'this.user.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" disabled=disabled></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Password</div><div id=password class=item-input-wrap><input type=password name=password placeholder="Modifica password"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Conferma password**</div><div id=passwordConfirm class=item-input-wrap><input type=password name=passwordConfirm placeholder="Conferma password"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nome</div><div id=firstname class=item-input-wrap><input type=text name=firstname placeholder="';
          r += Template7Helpers.js.call(ctx_2, 'this.user.firstname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Cognome *</div><div id=familyname class=item-input-wrap><input type=text name=familyname placeholder="';
          r += Template7Helpers.js.call(ctx_2, 'this.user.familyname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"></div></div></div></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Livello *</div><div id=role class=item-input-wrap><select name=role id=role-selector>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.new, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<option value="" selected=selected>-- Seleziona un livello --</option>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<option value=Admin>Admin</option><option value=Operation>Operatore</option><option value=Guest>Ospite</option></select></div></div></div></li></ul></form><div class="block block-strong"><div class=btn-inner>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.new, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a class="button button-fill text-color-black" href=# @click=save()>Aggiungi Utente! </a>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a class="button button-fill text-color-black" href=# @click=save()>Modifica ';
          r += Template7Helpers.js.call(ctx_2, 'this.user.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '! </a>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=block-footer>';
      r += Template7Helpers.if.call(ctx_1, 'this.user', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<p>* Campi obbligatori</p>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<p>** Obbligatorio solo se si vuole cambiare password</p>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },

  style: `
.btn-inner {
	width: 50%;
	margin: 0 auto;
}

.wrong-field {
	border-style: groove;
	border-color: #ffcc00;
	border-width: thin;
	border-radius: 5px;
}

`,
  styleScoped: false
};
    