
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { remoteDBdamages } from '../js/modules/db.js';
import { openMap } from '../js/modules/my_fun.js';
Template7.global = {
  imgs: {}
};
export default {
  data: function () {
    return {
      status: {
        label: ['non definito', 'buono', 'mediocre', 'critico', 'Aperto', 'Assegnato', 'Completato'],
        color: ['text-color-gray', 'text-color-green', 'text-color-yellow', 'text-color-red', 'text-color-gray', 'text-color-blue', 'text-color-green']
      },
      dmgstatus: {
        label: ['non definito', 'buono', 'mediocre', 'critico', 'Aperto', 'Assegnato', 'Completato'],
        color: ['text-color-gray', 'text-color-green', 'text-color-yellow', 'text-color-red', 'text-color-gray', 'text-color-blue', 'text-color-green']
      }
    };
  },
  methods: {
    openMap(lat, lon) {
      openMap(lat, lon);
    },

    openRouteLink(route_id, route_name) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$app.views.main.router.navigate({
        url: '/route-detail-page/' + route_id + '/' + route_name + '/'
      });
    },

    openActLink(activity_id, activity_name) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$app.views.main.router.navigate({
        url: '/activity-detail-page/' + activity_id + '/' + activity_name + '/'
      });
    }

  },
  on: {
    pageBeforeOut: function (page) {
      Template7.global.imgs = {};
    },
    pageInit: function (page) {
      // Photo initialization
      var photoArr = [];
      let imagecapt = '';

      for (var att in this.damage._attachments) {
        if (att == 'mainImg.jpg') {
          Template7.global.imgs.mainimg = this.damage._attachments[att].data;
          imagecapt = 'Immagine principale';
        } else {
          Template7.global.imgs[att] = this.damage._attachments[att].data;
          imagecapt = 'Immagine aggiuntiva n. ' + att.split('addImg')[1];
        }

        photoArr.push({
          url: 'data:image/jpg; base64, ' + this.damage._attachments[att].data,
          caption: imagecapt
        });
      }

      ;
      var phBrowser = this.$app.photoBrowser.create({
        photos: photoArr
      });
      this.$$('.card-header').on('click', el => {
        phBrowser.open();
      }); // Events

      this.$$('#status').on('change', e => {
        var formData = this.$app.form.convertToData('#dmg-form');
        this.damage.colorstatForm = this.dmgstatus.color[this.dmgstatus.label.indexOf(formData.status)];
        this.$setState(this.damage.colorstatForm);
      });
      this.$$('#fixstatus').on('change', e => {
        var formData = this.$app.form.convertToData('#dmg-form');
        this.damage.colorfixstatForm = this.dmgstatus.color[this.dmgstatus.label.indexOf(formData.fixstatus)];
        this.$setState(this.damage.colorfixstatForm);
      });
      this.$$('#saveState').on('click', e => {
        this.$app.preloader.show();
        var formData = this.$app.form.convertToData('#dmg-form');
        remoteDBdamages.get(this.damage._id, {
          attachments: true
        }, (err, res) => {
          if (!err) {
            let dmgNewStatus = res;
            dmgNewStatus.status = this.dmgstatus.label.indexOf(formData.status);
            dmgNewStatus.fix_status = this.dmgstatus.label.indexOf(formData.fixstatus) - 4;
            let dd = new Date();
            let newNoteStatus = '';
            let newNoteFixStatus = '';
            let newNote = '';
            if (dmgNewStatus.status != this.damage.status) newNoteStatus = 'St: ' + formData.status;
            if (dmgNewStatus.fix_status != this.damage.fix_status) newNoteFixStatus = 'Att: ' + formData.fixstatus;
            if (formData.note) newNote = 'Note: ' + formData.note;

            if (newNoteStatus || newNoteFixStatus || newNote) {
              let noteStr = '[' + dd.toLocaleString() + ' - ' + this.$app.data.user.username + '] ' + newNoteStatus + ' ' + newNoteFixStatus + ' ' + newNote;

              if (dmgNewStatus.notes) {
                dmgNewStatus.notes = dmgNewStatus.notes + '<br>' + noteStr;
              } else {
                dmgNewStatus.notes = noteStr;
              }

              remoteDBdamages.put(dmgNewStatus, (err, res) => {
                if (!err) {
                  this.damage.status = dmgNewStatus.status;
                  this.damage.fix_status = dmgNewStatus.fix_status;
                  this.damage.notes = dmgNewStatus.notes;
                  this.damage.colorstat = this.dmgstatus.color[this.damage.status];
                  this.damage.colorfixstat = this.dmgstatus.color[this.damage.fix_status + 4];
                  this.damage.labelstat = this.dmgstatus.label[this.damage.status];
                  this.damage.labelfixstat = this.dmgstatus.label[this.damage.fix_status + 4];
                  this.damage.colorstatForm = this.damage.colorstat;
                  this.damage.colorfixstatForm = this.damage.colorfixstat;
                  this.$setState(this.damage, () => {
                    this.$app.dialog.alert('attività modificata');
                    this.$app.preloader.hide();
                  });
                }
              });
            } else {
              this.$app.dialog.alert('nessuna modifica selezionata');
              this.$app.preloader.hide();
            }
          } else {
            this.$app.dialog.alert('errore nel salvataggio. riprovare.');
            this.$app.preloader.hide();
          }
        });
        this.$$('.dmg-sheet').on('sheet:open', e => {
          let form = {
            note: ''
          };
          this.$app.form.fillFromData('#dmg-form', form);
        });
      });
      this.$setState();
    }
  },
  id: '3f6c59364d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="damage-detail-page">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n\n			<div class="navbar-inner sliding">\n				<div class="left">\n					<a href="#" class="link icon-only back" data-ignore-cache="true" data-force="true">\n						<i class="icon f7-icons">arrow_left</i>\n					</a>\n					<a href="/" class="link icon-only">\n						<i class="icon f7-icons">\n							house\n						</i>\n					</a>\n				</div>\n\n				<div class="title">Controllo ';
      r += c(ctx_1.damage.date_time, ctx_1);
      r += ' su ';
      r += c(ctx_1.damage.route.name, ctx_1);
      r += '</div>\n				<div class="right">\n					<a href="#" class="link icon-only" @click="window.print()">\n						<i class="icon f7-icons tooltip-init"\n						   data-tooltip="Stampa pagina web">\n							printer_fill\n						</i>\n					</a>\n				</div>\n			</div>\n\n		</div>\n\n	<div class="page-content">\n		<div class="card main-card">\n			<div class="card-header" style="background-image:url(\'data:image/jpg; base64,';
      r += c(Template7.global.imgs.mainimg, ctx_1);
      r += '\')">\n				';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.status === 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<i class="f7-icons text-color-gray">\n					exclamationmark_triangle_fill\n				</i>\n				<i class="status_label">Stato: Non Definito</i>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.status === 1', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<i class="f7-icons text-color-green">\n					exclamationmark_triangle_fill\n				</i>\n				<i class="status_label">Stato: Buono</i>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.status === 2', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<i class="f7-icons text-color-yellow">\n					exclamationmark_triangle_fill\n				</i>\n				<i class="status_label">Stato: Mediocre</i>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.status === 3', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				<i class="f7-icons text-color-red">\n					exclamationmark_triangle_fill\n				</i>\n				<i class="status_label">Stato: Critico</i>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n			<div class="card-content card-content-padding">\n				<a href="#" class="sheet-open" data-sheet=".dmg-sheet">\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.fix_status === 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<i class="f7-icons text-color-gray">\n						square_pencil\n					</i>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.fix_status === 1', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<i class="f7-icons text-color-blue">\n						square_pencil\n					</i>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.fix_status === 2', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<i class="f7-icons text-color-green">\n						square_pencil\n					</i>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</a>\n				<p class="status_label sheet-open" data-sheet=".dmg-sheet">\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.fix_status === 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					Aperto\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.fix_status === 1', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					Assegnato\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.fix_status === 2', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					Completato\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</p>\n\n				<p>\n					Itinerario:\n					<span class="gpstxt text-color-yellow" @click="openRouteLink(\'';
      r += c(ctx_1.damage.route.id, ctx_1);
      r += '\', \'';
      r += c(ctx_1.damage.route.name, ctx_1);
      r += '\')">\n						';
      r += c(ctx_1.damage.route.name, ctx_1);
      r += '\n					</span>\n					<br>\n					Attività del\n					<span class="gpstxt text-color-yellow" @click="openActLink(\'';
      r += c(ctx_1.damage.activity.id, ctx_1);
      r += '\', \'';
      r += c(ctx_1.damage.activity.name, ctx_1);
      r += '\')">\n						';
      r += Template7Helpers.js.call(ctx_1, "this.damage.activity.id.split('-')[2].split('T')[0]", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '/';
      r += Template7Helpers.js.call(ctx_1, "this.damage.activity.id.split('-')[1]", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '/';
      r += Template7Helpers.js.call(ctx_1, "this.damage.activity.id.split('-')[0]", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						-\n						';
      r += Template7Helpers.js.call(ctx_1, "this.damage.activity.id.split('-')[2].split('T')[1].split('.')[0]", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						<!-- ';
      r += c(ctx_1.damage.activity.name, ctx_1);
      r += ' -->\n					</span>\n				</p>\n\n				<p class="text-color-blue">\n					<i class="icon f7-icons tooltip-init text-color-yellow" data-tooltip=\'Apri mappa!\' @click="openMap(\'';
      r += c(ctx_1.damage.gps.lat, ctx_1);
      r += '\', \'';
      r += c(ctx_1.damage.gps.lon, ctx_1);
      r += '\')">\n						map_pin_ellipse\n					</i>\n					';
      r += c(ctx_1.damage.gps.lat, ctx_1);
      r += ', ';
      r += c(ctx_1.damage.gps.lon, ctx_1);
      r += ' - acc: ';
      r += c(ctx_1.damage.gps.acc, ctx_1);
      r += '\n				</p>\n					<p class="text-color-gray">Segnalato da (';
      r += c(ctx_1.damage.user.firstname, ctx_1);
      r += ' ';
      r += c(ctx_1.damage.user.familyname, ctx_1);
      r += ') il ';
      r += c(ctx_1.damage.date_time, ctx_1);
      r += '</p>\n				</div>\n				<div class="card-footer">';
      r += c(ctx_1.damage.notes, ctx_1);
      r += '</div>\n			</div>\n			';
      r += Template7Helpers.each.call(ctx_1, Template7.global.imgs, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			';
          r += Template7Helpers.js_if.call(ctx_2, '@key != "mainimg"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n			<div class="card main-card">\n				<div class="card-header" style="background-image:url(\'data:image/jpg; base64,';
              r += c(ctx_3, ctx_3);
              r += '\')"></div>\n				<div class="card-content card-content-padding">\n					<p>Immagine aggiuntiva n. ';
              r += Template7Helpers.js.call(ctx_3, "(@key).split('addImg')[1]", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</p>\n				</div>\n			</div>\n			';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n			';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n		</div>\n		<!-- Top sheet -->\n		<div class="sheet-modal dmg-sheet theme-dark" style="height: 270px;">\n			<div class="toolbar toolbar-bottom" style="border-top: 1px solid #ffcc00; border-bottom: 1px solid #ffcc0040;">\n				<div class="toolbar-inner">\n					<div class="left"><a id="saveState" class="link sheet-close" href="#">Salva</a></div>\n					<div class="title"><b>Modifica Stato</b></div>\n					<div class="right"><a class="link sheet-close" href="#">Chiudi</a></div>\n				</div>\n			</div>\n			<div class="sheet-modal-inner" style="background-color: #ffcca008;">\n				<div class="page-content">\n					<div class="block">\n						<form class="list inset" id="dmg-form">\n							<ul>\n								<li>\n									<div class="item-content item-input">\n										<div class="item-media"><i class="f7-icons ';
      r += c(ctx_1.damage.colorstatForm, ctx_1);
      r += '">exclamationmark_triangle_fill</i></div>\n										<div class="item-inner">\n											<!-- <div class="item-title item-label">Livello *</div> -->\n											<div id="status" class="item-input-wrap">\n												<select name="status">\n													<option value="non definito" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelstat == "non definito"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Non Definito</option>\n													<option value="buono" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelstat == "buono"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Buono</option>\n													<option value="mediocre" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelstat == "mediocre"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Mediocre</option>\n													<option value="critico" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelstat == "critico"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Critico</option>\n												</select>\n											</div>\n										</div>\n									</div>\n								</li>\n								<li>\n									<div class="item-content item-input">\n										<div class="item-media"><i class="f7-icons ';
      r += c(ctx_1.damage.colorfixstatForm, ctx_1);
      r += '">square_pencil</i></div>\n										<div class="item-inner">\n											<!-- <div class="item-title item-label">Livello *</div> -->\n											<div id="fixstatus" class="item-input-wrap">\n												<select name="fixstatus">\n													<option value="Aperto" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelfixstat == "Aperto"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Aperto</option>\n													<option value="Assegnato" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelfixstat == "Assegnato"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Assegnato</option>\n													<option value="Completato" ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.damage.labelfixstat == "Completato"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'selected';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>Completato</option>\n												</select>\n											</div>\n										</div>\n									</div>\n								</li>\n								<li>\n									<div class="item-content item-input">\n										<div class="item-media"></div>\n										<div class="item-inner">\n											<!-- <div class="item-title item-label">Username *</div> -->\n											<div id="note" class="item-input-wrap">\n												<textarea class="resizable" name="note" placeholder="note"></textarea>\n											</div>\n										</div>\n									</div>\n								</li>\n							</ul>\n						</form>\n					</div>\n				</div>\n				\n			</div>\n		</div>\n\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    