
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {};
  },
  methods: {},
  on: {
    pageInit: function (page) {
      // Initialize searchbar
      var searchbar = this.$app.searchbar.create({
        el: '.search-pdi',
        searchContainer: '.searchbar-found-pdi',
        searchIn: ['.item-search-pdi']
      });
    }
  },
  id: '87423d9262',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=pdi-page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left>';
      r += Template7Helpers.if.call(ctx_1, root.linkBack, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only back"><i class="icon f7-icons">arrow_left</i> </a>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only panel-open"><i class="icon f7-icons">menu</i> </a>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=title>Punti di Interesse</div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div><div class=subnavbar><form class="searchbar search-pdi"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca PDI"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div><div class=page-content><!-- Searchbar backdrop --><div class=searchbar-backdrop></div><div class="list searchbar-found-pdi"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.routes, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href="/route-detail-page/';
          r += c(ctx_2.doc._id, ctx_2);
          r += '/\'';
          r += c(ctx_2.doc.name, ctx_2);
          r += '\'" class="item-link item-content"><div class=item-media><i class="f7-icons text-color-blue">scope</i></div><div class=item-inner><div class="item-title item-search-pdi">';
          r += c(ctx_2.doc.name, ctx_2);
          r += '</div><div class="item-after item-search-pdi">';
          r += c(ctx_2.doc._id, ctx_2);
          r += '</div></div></a></li><li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li></ul></div><div class="list media-list searchbar-not-found"><ul><li><a href=# class=item-content><div class=item-inner><div class=item-title-row><div class=item-title><p>Nessun PDI Trovato</p></div></div></div></a></li></ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    