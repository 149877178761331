import {putUpdateUser, putNewUser, deleteUser} from '../modules/couchdb-user-module';

function checkFields(self) {
	var missing_fields = false;
	var userData = self.$app.form.convertToData('#user-form');
	if (self.new) {
		self.$$('.wrong-field').removeClass('wrong-field');
		Object.keys(userData).forEach((el) => {
			if (!userData[el]) {
				self.$$('#'+ el).addClass('wrong-field');
				missing_fields = true;
			}
		});
		if (userData['password'] !== userData['passwordConfirm']) {
			self.$app.dialog.alert('Inserire la stessa password!', () => {
				self.$$('#passwordConfirm').addClass('wrong-field');
			});
			missing_fields = true;
		}
	} else {
		self.$$('.wrong-field').removeClass('wrong-field');
		Object.keys(userData).forEach((el) => {
			if (userData['password']) {
				if (!userData['passwordConfirm']) {
					self.$$('#passwordConfirm').addClass('wrong-field');
					missing_fields = true;
				} else {
					if (userData['password'] !== userData['passwordConfirm']) {
						self.$app.dialog.alert('Inserire la stessa password!', () => {
							self.$$('#passwordConfirm').addClass('wrong-field');
						});
						missing_fields = true;
					}
				}
			}
		});
	}
	return !missing_fields;
}

export function saveUser(self) {
	if(!checkFields(self)) {
		return;
	}
	var userData = self.$app.form.convertToData('#user-form');

	if (self.new) {
		self.$app.dialog.confirm('Creare nuovo utente?', () => {
			self.$app.preloader.show();
			putNewUser(userData, (error, response) => {
				if(error) {
					self.$app.preloader.hide();
					self.$app.dialog.alert('Errore di rete! Riprova più tardi!', () => {
						self.$router.navigate('/user-page/');
					});
				} else {
					self.$app.preloader.hide();
					self.$app.dialog.alert('Utente creato con successo!', () => {
						self.$router.navigate('/user-page/');
					});
				}
			});
		});
	} else {
		self.$app.dialog.confirm('Modificare utente?', () => {
			self.$app.preloader.show();
			putUpdateUser(userData, (error, response) => {
				if(error) {
					self.$app.preloader.hide();
					self.$app.dialog.alert('Errore di rete! Riprova più tardi!', () => {
						self.$router.navigate('/user-page/');
					});
				}	else {
					self.$app.preloader.hide();
					self.$app.dialog.alert('Utente modificato con successo!', () => {
						self.$router.navigate('/user-page/');
					});
				}
			});
		});
	}
}


export function removeUser(self) {
	if (self.user.name === self.$app.data.user.username) {
		self.$app.dialog("Non eliminare te stesso!");
		return;
	}

	self.$app.dialog.confirm(`Vuoi eliminare ${self.user.name}?`, () => {
		self.$app.preloader.show();
		deleteUser(self.user.name, (error, response) => {
			if(error) {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Errore di rete! Riprova più tardi!', () => {
					self.$router.navigate('/user-page/');
				});
			} else {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Utente eliminato con successo!', () => {
					self.$router.navigate('/user-page/');
				});
			}
		});
	});
}
