
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { getAllUsers } from '../js/modules/db.js';
export default {
  data: function () {
    return {
      levels: ['admin', 'operatore', 'ospite']
    };
  },
  methods: {},
  on: {
    pageInit: function (page) {
      var searchbar = this.$app.searchbar.create({
        el: '.search-user',
        searchContainer: '.searchbar-found',
        searchIn: ['.item-search-user']
      });
      this.$setState();
      /*
      getAllUsers((res)=>{
      	this.users = res;
      	this.users.forEach(el => el.doc.levelname = this.levels[el.doc.level]);
      	});
      */
    }
  },
  id: '24fe79d0bb',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=user-page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link icon-only panel-open"><i class="icon f7-icons">menu</i> </a><a href=/ class="link icon-only" data-ignore-cache=true data-force=true><i class="icon f7-icons">house</i></a></div><div class=title>Lista Utenti</div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill </i></a><a href=/new-user/ class="link icon-only"><i class="icon f7-icons">person_badge_plus</i></a></div></div><div class=subnavbar><form class="searchbar search-user"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca Utente"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div><div class=page-content><!-- Searchbar backdrop --><div class=searchbar-backdrop></div><div class="list searchbar-found"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.users, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/edit-user-page/id/';
          r += c(ctx_2.doc.name, ctx_2);
          r += ' class="item-link item-content"><div class=item-media><i class=f7-icons>person</i></div><div class=item-inner><div class="item-title item-search-user">';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.firstname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.familyname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class="item-after item-search-user">';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.roles[0]', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></a></li><li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li></ul></div><div class="block searchbar-not-found"><div class=block-inner>Nothing found</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    