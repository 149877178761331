
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { remoteDBroutes } from '../js/modules/db.js';
import { checkRouteId } from '../js/modules/db.js';
import { getGpsFromAddress } from '../js/modules/my_fun.js';
import { openMap } from '../js/modules/my_fun.js';
var toggle;
export default {
  data: function () {
    return {
      route: {},
      allDisable: false
    };
  },
  methods: {
    save() {
      this.checkFields();
      var reds = document.getElementsByClassName('wrong-field').length;

      if (reds == 0) {
        var formData = this.$app.form.convertToData('#route-form'); //store the new user

        this.$app.dialog.confirm("Vuoi salvare l'itinerario?", () => {
          this.$app.preloader.show();
          delete formData.gps_coords;

          if (this.route.gps_auto == true) {
            getGpsFromAddress(formData.address, gps => {
              if (this.route._rev) {
                formData._rev = this.route._rev;
              }

              ;
              formData.gps_lat = gps.lat;
              formData.gps_lon = gps.lon;
              formData.gps_auto = true;
              remoteDBroutes.put(formData, (err, res) => {
                if (err) {
                  this.$app.dialog.alert("Errore durante il salvataggio dell'itinerario");
                } else {
                  this.$app.preloader.hide();
                  this.$router.navigate('/route-page/');
                }
              });
            });
          } else {
            if (this.route._rev) {
              formData._rev = this.route._rev;
            }

            ;
            formData.gps_auto = false;
            remoteDBroutes.put(formData, (err, res) => {
              if (err) {
                this.$app.dialog.alert("Errore durante il salvataggio dell'itinerario");
              } else {
                this.$app.preloader.hide();
                this.$router.navigate('/route-page/');
              }
            });
          }

          ;
        });
      } else {
        this.$app.dialog.alert("Tutti i campi sono obbligatori");
      }

      ;
    },

    checkFields() {
      var formData = this.$app.form.convertToData('#route-form');
      this.$$('.wrong-field').removeClass('wrong-field');
      Object.keys(formData).forEach(el => {
        if (!formData[el]) {
          this.$$('#' + el).addClass('wrong-field');
        }

        ;
      });
    },

    removeRoute() {
      this.$app.dialog.confirm("Vuoi eliminare l'itinerario?", () => {
        remoteDBroutes.remove(this.route._id, this.route._rev, (err, res) => {
          if (!err) {
            this.$router.navigate('/route-page/');
          } else {
            this.$app.dialog.alert("Errore durante l'eliminazione dell'itinerario");
          }
        });
      });
    },

    openMap(lat, lon) {
      openMap(lat, lon);
    }

  },
  on: {
    pageInit: function (page) {
      if (this.$app.data.user.level != 0) this.allDisable = true;
      toggle = this.$app.toggle.create({
        el: '#gps_auto_tgl',
        on: {
          toggleChange: () => {
            var formData = this.$app.form.convertToData('#route-form');
            this.route.gps_auto_tgl = formData.gps_auto_tgl;

            if (formData.gps_auto_tgl[0] == 'on') {
              this.route.gps_auto = true;
            } else {
              this.route.gps_auto = false;
            }

            this.$setState(this.route, () => {
              let gpsHolder = {};
              gpsHolder.gps_lat = this.route.gps_lat;
              gpsHolder.gps_lon = this.route.gps_lon;
              this.$app.form.fillFromData('#route-form', gpsHolder);
              this.checkFields();
            });
          }
        }
      });

      if (this.$route.params.id && this.$route.params.id != 'new') {
        remoteDBroutes.get(this.$route.params.id, (err, res) => {
          if (!err) {
            this.route = res;
            this.$setState(this.allDisable, () => {
              this.$setState(this.route, () => {
                if (this.route.gps_auto == true) {
                  toggle.toggle();
                }

                ;
                this.$app.form.fillFromData('#route-form', this.route);
                this.checkFields();
              });
            });
          }
        });
      } else {
        checkRouteId(result => {
          this.route._id = result;
          this.route.gps_auto = true;
          this.$app.form.fillFromData('#route-form', this.route);
          this.$setState(this.route, () => {
            this.$app.toggle.get('#gps_auto_tgl').toggle();
            this.checkFields();
          });
        });
      }

      this.$$('input').on('change', e => this.checkFields());
      this.$$('select').on('change', e => this.checkFields());
    }
  },
  id: '32e16dafb4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=edit-route-page><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon f7-icons">arrow_left</i></a></div><div class=title>';
      r += Template7Helpers.js_if.call(ctx_1, '@root.$route.params.id != "new"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' Modifica ';
          r += c(root.route.name, ctx_2);
          r += ' (';
          r += c(root.route._id, ctx_2);
          r += ')</div><div class=right><a href=# class="link icon-only" @click=removeRoute()><i style="right: 25px" class="icon f7-icons">locationminus_circle</i></a></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' Aggiungi Itinerario</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=page-content><form class=list id=route-form><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">ID (automatico)</div><div id=_id class=item-input-wrap><input type=text name=_id placeholder=ID disabled=disabled></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Nome *</div><div id=name class=item-input-wrap><input type=text name=name placeholder=nome></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Indirizzo *</div><div id=address class=item-input-wrap><input type=text name=address placeholder=indirizzo></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Lunghezza tratta in metri *</div><div id=length_mt class=item-input-wrap><input type=number name=length_mt placeholder="lunghezza [m]"></div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>GPS auto *</div><div class=item-after><label id=gps_auto_tgl class=toggle><input type=checkbox name=gps_auto_tgl><i class=toggle-icon></i></label></div></div></div></li>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.route.gps_auto == false', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">GPS latitudine *</div><div id=gps_lat class=item-input-wrap><input type=number name=gps_lat placeholder="gps coord"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">GPS longitudine *</div><div id=gps_lon class=item-input-wrap><input type=number name=gps_lon placeholder="gps coord"></div></div></div></li>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.route.gps_lat && this.route.gps_lon', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.route.gps_lat && this.route.gps_lon', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><div class="item-content item-input" @click=openMap(';
              r += c(ctx_3.route.gps_lat, ctx_3);
              r += ',';
              r += c(ctx_3.route.gps_lon, ctx_3);
              r += ')><div class=item-inner><div class="item-title item-label"></div><div id=gps_lon class=item-input-wrap><input type=text disabled=disabled name=gps_coords placeholder="gps coord" class=text-color-blue value="';
              r += c(root.route.gps_lat, ctx_3);
              r += ' - ';
              r += c(root.route.gps_lon, ctx_3);
              r += '"></div></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></form><div class="block block-strong"><div class=btn-inner><a class="button button-fill text-color-black" href=# @click=save()>SAVE</a></div></div><div class=block-footer><p>* Campi obbligatori</p></div></div>';
      return r;
    }(this);
  },

  style: `
.btn-inner {
	width: 50%;
	margin: 0 auto;
}

.wrong-field {
	border-style: groove;
	border-color: #ffcc00;
	border-width: thin;
	border-radius: 5px;
}

`,
  styleScoped: false
};
    