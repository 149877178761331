import {app} from '../app';

import PouchDB from 'pouchdb-browser';
import PouchDBAuth from 'pouchdb-authentication';

PouchDB.plugin(PouchDBAuth)

var couch_address = 'https://gestioneintegrataservizi.it:3395'; // GIS

var remoteDBroot = new PouchDB(couch_address, {skip_setup: true});

export function login(self) {
	self.$app.preloader.show();
	remoteDBroot.logIn(self.username, self.password, (error, response) => {
		if(error) {
			self.$$('#login-password-value').val('');
			self.password = undefined;
			if (error.status === 401 || error.name === 'forbidden'){
				self.$$('#invalid-credentials-id').css('display', 'flex');
			} else {
				self.$$('#network-error-login').css('display', 'flex');
			}
			self.$app.preloader.hide();
			return;
		}
		if (response.roles.includes('Admin') || response.roles.includes('Guest')) {
			self.$app.data.user.username = response.name;
			self.$app.data.user.roles = response.roles;
			// self.$app.panel.get('.panel-left').toggle();
			self.$app.preloader.hide();
			self.$router.navigate('/pdi-page/', {reloadCurrent: true});
		} else {
			self.$$('#login-password-value').val('');
			self.password = undefined;
			self.$$('#invalid-credentials-id').css('display', 'flex');
			self.$app.preloader.hide();
		}
	});
}

export async function isUserAuthenticated(callback) {
    const isLoggedIn = await remoteDBroot.getSession((err, res) =>{});
    if (!isLoggedIn.ok) {
        // Network error
        return callback(false);
    }
    if (!isLoggedIn.userCtx.name) {
        // nobody's logged in
        return callback(false);
    } else {
			// Logged in
			if (isLoggedIn.userCtx.roles.includes('Operation')) {
				return callback(false);
			}
			app.data.user.roles = isLoggedIn.userCtx.roles;
			app.data.user.username = isLoggedIn.userCtx.name;
			return callback(true);
		}
}

export async function isUserAuthenticatedAndAdmin(callback) {
	const isLoggedIn = await remoteDBroot.getSession((err, res) =>{});
	if (!isLoggedIn.ok) {
			// Network error
			return callback(false, false);
	}
	if (!isLoggedIn.userCtx.name) {
		// nobody's logged in
		return callback(false, false);
	}else {
		// Logged in
		if (isLoggedIn.userCtx.roles.includes('Operation')) {
			return callback(false, false);
		}
		app.data.user.roles = isLoggedIn.userCtx.roles;
		app.data.user.username = isLoggedIn.userCtx.name;
		if(app.data.user.roles.includes("Admin")){
				return callback(true, true);
		}
		else {
				return callback(true, false);
		}
	}
}

export function userLogout(self) {
	self.$app.preloader.show();
	remoteDBroot.logOut((err, res) =>{
		if (err) {
			// Network error
			self.$app.preloader.hide();
		} else {
			self.$app.preloader.hide();
			self.$app.views.main.router.navigate('/login/');
		}
	});
}
