export default {
  on: {
    pageInit: function (page) {
      //this.$app.panel.get('.panel-left').close();
      setTimeout(() => {
        setTimeout(() => {
          this.$app.preloader.hide();
          this.$router.navigate('/');
        }, 1500);
        this.$app.preloader.show();
      }, 1500);
    }
  },
  id: '85aa6e3505',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=#><i class="icon f7-icons">exclamationmark_circle</i></a></div><div class=title>Not found</div></div></div><div class=page-content><div class="block block-strong"><p>Sorry</p><p>Requested content not found.</p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};