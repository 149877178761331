export function getGpsFromAddress(address, callback){
	
    let xhr = new XMLHttpRequest();
	// open a connection 
	
	let gpsggurl = 'https://maps.googleapis.com/maps/api/geocode/json?address='+ address.replace(/ /g, '+') +'&key=AIzaSyDxK5180RJZW4fcHencZSKHMtD0fPBhwcI';
    xhr.open("GET", gpsggurl);
	
    xhr.onload = function () {
		console.log(xhr);
        if (xhr.readyState === 4 && xhr.status === 200) {
			let resp = JSON.parse(xhr.responseText);
			if(resp.status == 'OK') {
				let gps = {
					lat: resp.results[0].geometry.location.lat,
					lon: resp.results[0].geometry.location.lng
				};
				callback(gps);
			}else{
				console.log('errore risposta ggapi');
				callback('err');
			};
        }else{
			console.log('errore connessione ggapi');
			callback('err');
		} 
    };
	
	xhr.onerror = function () {
		console.log('errore connessione ggapi');
		callback('err');
	};
    
	xhr.send();
}

export function openMap(gps_lat, gps_lon){
	let addr = 'http://www.google.com/maps?q='+gps_lat+','+gps_lon;
	window.open(addr);
}

export function openAppLink(self, route_id, pageTo){
	self.$app.views.main.router.navigate({url: pageTo + 'id/'+ route_id});
}

export function sortArrayByElement(self, el, obj){
	function date2ms(d){
		var date = new Date(d.replace(' - ',' '));
		return date.getTime();
	}

	if (el == 'date_time' || el == 'start_time'){
		self[obj] = self[obj].sort((a, b) => (date2ms(a[el]) > date2ms(b[el])) ? -1 : 1);
	}else{
		self[obj] = self[obj].sort((a, b) => (a[el] > b[el]) ? -1 : 1);
	}
	
	let searchbar = self.$app.searchbar.get('.search-damage');
	if (!searchbar) searchbar = self.$app.searchbar.get('.search-activity');
	
	self.$app.preloader.show();
	self.$setState(self[obj],()=>{
		let queryStr = searchbar.query;
		searchbar.query = "";
		searchbar.search(queryStr);
		self.$app.preloader.hide();
	});
}