
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { openMap } from '../js/modules/my_fun.js';
import { getAllDamagesWithLabelsByTimeRange } from '../js/modules/couchdb-pdi-module';
export default {
  methods: {
    openMap(lat, lon) {
      openMap(lat, lon);
    },

    openDmgDetail(dmg_id) {
      // openAppLink(this, dmg_id, '/damage-detail-page/');
      this.$app.views.main.router.navigate({
        url: '/damage-detail-page/' + dmg_id + '/'
      });
    },

    openRouteLink(route_id, route_name) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$app.views.main.router.navigate({
        url: '/route-detail-page/' + route_id + '/' + route_name + '/'
      });
    },

    openActLink(activity_id, activity_name) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$app.views.main.router.navigate({
        url: '/activity-detail-page/' + activity_id + '/' + activity_name + '/'
      });
    }

  },
  on: {
    pageInit: function (page) {
      var searchbar = this.$app.searchbar.create({
        el: '.search-damage',
        searchContainer: '.searchbar-found-dmg',
        searchIn: ['.item-search-damage']
      });
      /*
       *	Infinite scroll\
       */

      if (this.linkBack) {
        this.$('.infinite-scroll-preloader').css('display', 'none');

        if (this.damages.length === 0) {
          this.$('.searchbar-not-found').css('display', 'block');
        } else {
          this.$('.searchbar-not-found').css('display', 'none');
        }
      } else {
        if (this.damages.length < 15) {
          this.$('.infinite-scroll-preloader').css('display', 'none');

          if (this.damages.length === 0) {
            this.$('.searchbar-not-found').css('display', 'block');
          }
        } else {
          this.$('.infinite-scroll-preloader').css('display', 'block');
        }

        var self = this;
        var allow_infinite = true;
        var last_time = this.last_time;
        this.$('#damages-scroll').on('infinite', function () {
          if (!allow_infinite) return;
          allow_infinite = false;
          var start_time = last_time;
          var end_time = new Date(last_time.getFullYear(), last_time.getMonth(), last_time.getDate() - 1);
          getAllDamagesWithLabelsByTimeRange(start_time, end_time, (error, response) => {
            if (error) {
              self.$app.dialog.alert('Non riesco a caricare altre criticità.<br>Riprova più tardi.', () => {
                self.$router.refreshPage();
              });
            } else {
              if (response.length === 0) {
                if (end_time <= new Date(2021, 0, 1)) {
                  self.$('.infinite-scroll-preloader').css('display', 'none');
                  return;
                }

                allow_infinite = true;
                last_time = end_time;
                self.$('#damages-scroll').trigger('infinite');
              } else {
                self.damages = self.damages.concat(response);
                self.$('.searchbar-not-found').css('display', 'none');

                if (self.damages.length > 10) {
                  self.$('.infinite-scroll-preloader').css('display', 'block');
                }

                self.$setState();

                if (end_time <= new Date(2021, 0, 1)) {
                  self.$('.infinite-scroll-preloader').css('display', 'none');
                  return;
                }

                last_time = end_time;
                allow_infinite = true;

                if (self.damages.length < 10) {
                  self.$('#damages-scroll').trigger('infinite');
                }
              }
            }
          });
        });

        if (this.damages.length <= 10) {
          this.$('#damages-scroll').trigger('infinite');
        }
      }

      this.$setState();
    }
  },
  id: '652485cb51',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=damage-page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.linkBack, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only back" data-ignore-cache=true data-force=true><i class="icon f7-icons">arrow_left </i></a>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only panel-open"><i class="icon f7-icons">menu </i></a>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <a href=/ class="link icon-only"><i class="icon f7-icons">house</i></a></div><div class=title>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.linkBack, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' Attività del ';
          r += c(ctx_2.activity_name, ctx_2);
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' Lista Criticità ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div><div class=subnavbar><form class="searchbar search-damage"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca Criticità"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div><div class="page-content infinite-scroll-content" id=damages-scroll><!-- Searchbar backdrop --><div class=searchbar-backdrop></div><div class="list media-list searchbar-found-dmg"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.damages, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=# class="item-link item-content" @click="openDmgDetail(\'';
          r += c(ctx_2.id, ctx_2);
          r += '\')"><div class=item-inner><div class=item-title-row><div class="item-title item-search-damage">Controllo: ';
          r += c(ctx_2.doc.date_time, ctx_2);
          r += ' su ';
          r += c(ctx_2.doc.route.name, ctx_2);
          r += '</div><div class=item-after><i class="f7-icons ';
          r += c(ctx_2.doc.colorfixstat, ctx_2);
          r += ' tooltip-init" id=fix-status-tooltip-';
          r += c(ctx_2.id, ctx_2);
          r += ' data-tooltip="Att: ';
          r += c(ctx_2.doc.labelfixstat, ctx_2);
          r += '">square_pencil </i><i class="f7-icons ';
          r += c(ctx_2.doc.colorstat, ctx_2);
          r += ' tooltip-init" id=status-tooltip-';
          r += c(ctx_2.id, ctx_2);
          r += ' data-tooltip="Stato: ';
          r += c(ctx_2.doc.labelstat, ctx_2);
          r += '">exclamationmark_triangle_fill</i></div></div><div class="gpstxt item-subtitle" @click="openRouteLink(\'';
          r += c(ctx_2.doc.route.id, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.route.name, ctx_2);
          r += '\')">Itinerario: <span class="text-color-yellow item-search-damage">';
          r += c(ctx_2.doc.route.name, ctx_2);
          r += '</span></div><div class="gpstxt item-subtitle" @click="openActLink(\'';
          r += c(ctx_2.doc.activity.id, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.activity.name, ctx_2);
          r += '\')">Attività ID: <span class="text-color-yellow item-search-damage">';
          r += c(ctx_2.doc.activity.id, ctx_2);
          r += '</span></div><div class=item-subtitle>Posizione: <i class="icon f7-icons tooltip-init text-color-yellow" data-tooltip="Apri mappa!" @click="openMap(\'';
          r += c(ctx_2.doc.gps.lat, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.gps.lon, ctx_2);
          r += '\')">map_pin_ellipse</i></div><div class="item-text item-search-damage">Acquisito da ';
          r += c(ctx_2.doc.user.firstname, ctx_2);
          r += ' ';
          r += c(ctx_2.doc.user.familyname, ctx_2);
          r += ' il ';
          r += c(ctx_2.doc.date_time, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="list searchbar-not-found"><ul><li><a href=# class=item-content><div class=item-inner><div class=item-title-row><div class=item-title><p>Nessuna Criticità Trovata</p></div></div></div></a></li></ul></div><div class="preloader infinite-scroll-preloader"></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    