import { getWeeklyReport } from '../js/utils/reports.js';
export default {
  data: function () {
    return {
      week_calendar: undefined,
      time_range: []
    };
  },
  methods: {
    weeklyReport() {
      if (!this.week_calendar.value) {
        return;
      }

      var start_date = this.week_calendar.value[0];
      var end_date = this.week_calendar.value[1];
      this.time_range = [start_date, new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate(), 23, 59, 59, 999)];
      getWeeklyReport(this);
    }

  },
  on: {
    pageInit: function () {
      const page = this;
      var now = new Date();
      var currentDay = now.getDay();
      var weekEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - currentDay));
      this.week_calendar = this.$f7.calendar.create({
        inputEl: '#week-calendar',
        openIn: 'customModal',
        header: false,
        footer: true,
        footerPlaceholder: 'Fatto',
        rangePicker: true,
        direction: 'vertical',
        minDate: new Date(2021, 1, 8, 0, 0, 0, 0),
        maxDate: weekEnd,
        cssClass: 'theme-dark',
        dateFormat: 'dd/mm/yyyy',
        on: {
          change: function (calendar, value) {
            var selectedDay = value[0];
            var weekDay = selectedDay.getDay();

            if (weekDay === 0) {
              var weekStart = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate() - 6);
              var weekEnd = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate());
            } else {
              var weekStart = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate() - weekDay + 1);
              var weekEnd = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate() + (7 - weekDay));
            }

            calendar.value = [weekStart, weekEnd];
            calendar.update();
            page.$$('#week-button').prop('disabled', false);
          }
        }
      });
    }
  },
  id: '933baf5235',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=report-page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link icon-only panel-open"><i class="icon f7-icons">menu</i> </a><a href=/ class="link icon-only" data-ignore-cache=true data-force=true><i class="icon f7-icons">house</i></a></div><div class=title>Report</div></div></div><div class=page-content><div class=block-title style="text-align: center">Report Settimanale</div><div class="list no-hardlines-md" style="width: 40%; margin: auto;"><ul><li><div class="item-content item-input"><div class=item-inner><div class=item-input-wrap><input type=text name=week-selector placeholder="Seleziona una settimana" readonly=readonly id=week-calendar class=report-input-selector></div><button class=button @click=weeklyReport() id=week-button disabled=disabled>Ottieni Report Settimanale!</button></div></div></li></ul></div></div></div>';
      return r;
    }(this);
  },

  style: `

	.report-input-selector {
		text-align: center;
		text-align-last: center;
	}
`,
  styleScoped: false
};