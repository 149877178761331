
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { getAllRoutes } from '../js/modules/db.js';
var searchbarRoute;
export default {
  data: function () {
    return {
      routes: {},
      linkBack: false
    };
  },
  methods: {},
  on: {
    pageInit: function (page) {
      let route_id = this.$route.params.id;
      getAllRoutes(res => {
        this.routes = res;
        this.$setState(this.routes, () => {
          searchbarRoute = this.$app.searchbar.create({
            el: '.search-route',
            searchContainer: '.searchbar-found-route',
            searchIn: ['.item-search-route'],
            on: {
              search(sb, query, previousQuery) {//console.log(query, previousQuery);
              }

            }
          });

          if (route_id) {
            this.linkBack = true;
            this.$setState(this.linkBack);
            searchbarRoute.search(route_id);
          }

          ;
        });
      });
    }
    /*
    pageBeforeOut: function(page){
    	searchbar.search('');
    }*/

  },
  id: '42b0c48603',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=route-page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left>';
      r += Template7Helpers.if.call(ctx_1, root.linkBack, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only back"><i class="icon f7-icons">arrow_left</i> </a>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only panel-open"><i class="icon f7-icons">menu</i> </a>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <a href=/ class="link icon-only" data-ignore-cache=true data-force=true><i class="icon f7-icons">house</i></a></div><div class=title>Lista Itinerari</div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill </i></a><a href=/edit-route-page/id/new class="link icon-only"><i style="right: 25px" class="icon f7-icons">locationplus_circle</i></a></div></div><div class=subnavbar><form class="searchbar search-route"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca Itinerario"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div><div class=page-content><!-- Searchbar backdrop --><div class=searchbar-backdrop></div><div class="list searchbar-found-route"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.routes, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/edit-route-page/id/';
          r += c(ctx_2.doc._id, ctx_2);
          r += ' class="item-link item-content"><div class=item-media><i class=f7-icons>location</i></div><div class=item-inner><div class="item-title item-search-route">';
          r += c(ctx_2.doc.name, ctx_2);
          r += '</div><div class="item-after item-search-route">';
          r += c(ctx_2.doc._id, ctx_2);
          r += '</div></div></a></li><li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li></ul></div><div class="block searchbar-not-found"><div class=block-inner>Nessun Itinerario Trovato</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    