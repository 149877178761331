// import {checkLogin} from '../js/modules/db.js';
import { checkLogin } from '../js/utils/login-util.js';
import { md5 } from '../js/modules/md5fun.js';
export default {
  methods: {
    checkCredential() {
      // checkLogin(username, password, this);
      checkLogin(this);
    }

  },
  on: {
    pageInit() {
      this.$app.data.user = {};
      this.$app.panel.get('.panel-left').toggle();
      this.$$('input').on('keypress', e => {
        if (e.which === 13) {
          this.checkCredential();
        }
      });
    }

  },
  id: '41ab6deec0',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar no-swipeback theme-dark" data-name=login><div class="toolbar toolbar-bottom"><div class=toolbar-inner><div class=powered-by><span>Powered by</span> <a href=https://www.arpsoft.it/en class=external target=_blank><img src=../static/icons/Arpsoft_Logo_White.png class=powered-logo></a></div></div></div><div class="page-content login-screen-content"><div class=login-screen-title><img src=../static/icons/icon.png class=login-logo> <span class=logintitle>DrEAM Dashboard</span></div><div class=list><div class="block login-error" id=network-error-login><span class=network-error-message>Errore di rete! Riprova più tardi.</span></div><div class="block login-error" id=invalid-credentials-id><span class=invalid-credentials-message>Username o password errati!</span></div><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label" id=username-input-title>Username</div><div class=input-missing-message id=username-missing-message>Username non inserito</div><div class=item-input-wrap id=login-username-input><input type=text name=username value="" placeholder="Your username"></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label" id=password-input-title>Password</div><div class=input-missing-message id=password-missing-message>Username non inserito</div><div class=item-input-wrap id=login-password-input><input type=password name=password value="" placeholder="Your password" id=login-password-value></div></div></li></ul></div><div class=list><ul><li><a href=# class="item-link list-button login-button" @click=checkCredential>Log In</a></li></ul><div class=block-footer>Clicca per connetterti a DrEAM Dashboard ver ';
      r += c(ctx_1.$app.data.version.number, ctx_1);
      r += '</div><div class="block-footer gis-logo-login-div"><a href=https://www.gestioneintegrataservizi.it/home/ target=_blank class=external><img src=../static/icons/logo-gis-originale-black.svg height=120px></a></div></div><!-- <div class="list"> --><!-- <ul> --><!-- <li> --><!-- <a href="#" class="item-link list-button login-button" @click="ReqCredential"><i>Sign Up</i></a> --><!-- </li> --><!-- </ul> --><!-- </div> --></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};