import PouchDB from 'pouchdb-browser';
import PouchDBFind from 'pouchdb-find';
PouchDB.plugin(PouchDBFind);

const couch_address = 'https://gestioneintegrataservizi.it:3395';
export var remoteDBroutes = new PouchDB(couch_address + '/dream_routelist/', {skip_setup: true});
export var remoteDBactivities = new PouchDB(couch_address + '/dream_activities/', {skip_setup: true});
export var remoteDBdamages = new PouchDB(couch_address + '/dream/', {skip_setup: true});

export function getAllPdi(callback) {
	remoteDBroutes.allDocs({
		include_docs: true,
		endkey: '_design'
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		return callback(undefined, response.rows);
	});
}

function getAllActivities(callback) {
	remoteDBactivities.allDocs({
		include_docs: true,
		startkey: '_design',
		descending: true
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		} else {
			return callback(undefined, response.rows);
		}
	});
}

function getActivities(route_id, callback) {
	remoteDBactivities.allDocs({
		include_docs: true,
		startkey: '_design',
		descending: true
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		var activities = response.rows.filter( (activity) => {
			return activity.doc.route.id === route_id;
		});
		return callback(undefined, activities);
	});
}

function getAllDamages(callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: '_design',
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			return callback(undefined, response.rows);
		}
	});
}

export function getAllDamagesWithLabels(callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: '_design',
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			const label = [
				'non definito',
				'buono',
				'mediocre',
				'critico',
				'Aperto',
				'Assegnato',
				'Completato'
			];
			const color = [
				'text-color-gray',
				'text-color-green',
				'text-color-yellow',
				'text-color-red',
				'text-color-gray',
				'text-color-blue',
				'text-color-green'
			];
			response.rows.forEach((damage)=>{
				damage.doc.colorstat = color[damage.doc.status];
				damage.doc.colorfixstat = color[damage.doc.fix_status + 4];
				damage.doc.labelstat = label[damage.doc.status];
				damage.doc.labelfixstat = label[damage.doc.fix_status + 4];
			});
			return callback(undefined, response.rows);
		}
	});
}

export function getAllDamagesWithLabelsByTimeRange(start, end, callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: start.toISOString(),
		endkey: end.toISOString(),
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			const label = [
				'non definito',
				'buono',
				'mediocre',
				'critico',
				'Aperto',
				'Assegnato',
				'Completato'
			];
			const color = [
				'text-color-gray',
				'text-color-green',
				'text-color-yellow',
				'text-color-red',
				'text-color-gray',
				'text-color-blue',
				'text-color-green'
			];
			response.rows.forEach((damage)=>{
				damage.doc.colorstat = color[damage.doc.status];
				damage.doc.colorfixstat = color[damage.doc.fix_status + 4];
				damage.doc.labelstat = label[damage.doc.status];
				damage.doc.labelfixstat = label[damage.doc.fix_status + 4];
			});
			return callback(undefined, response.rows);
		}
	});
}

export function getDamageWithLabels(damage_id, callback) {
	remoteDBdamages.get(damage_id, { attachments: true },(error, damage) => {
		if(error) {
			return callback(error, undefined);
		}
		const status = {
			label : [
			'non definito',
			'buono',
			'mediocre',
			'critico',
			'Aperto',
			'Assegnato',
			'Completato'
			],
			color : [
			'text-color-gray',
			'text-color-green',
			'text-color-yellow',
			'text-color-red',
			'text-color-gray',
			'text-color-blue',
			'text-color-green'
			]
		};
		const dmgstatus = {
			label: [
			'non definito',
			'buono',
			'mediocre',
			'critico',
			'Aperto',
			'Assegnato',
			'Completato'
			],
			color : [
			'text-color-gray',
			'text-color-green',
			'text-color-yellow',
			'text-color-red',
			'text-color-gray',
			'text-color-blue',
			'text-color-green'
			]
		};
		damage.colorstat = dmgstatus.color[damage.status];
		damage.colorfixstat = dmgstatus.color[damage.fix_status + 4];
		damage.labelstat = dmgstatus.label[damage.status];
		damage.labelfixstat = dmgstatus.label[damage.fix_status + 4];
		damage.colorstatForm = damage.colorstat;
		damage.colorfixstatForm = damage.colorfixstat;

		callback(undefined, damage);
	});
}

export function getDamages(activity_id, callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: '_design',
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			var damages = response.rows.filter((damage) => {
				return damage.doc.activity.id === activity_id;
			});
			return callback(undefined, damages);
		}
	});
}

export function getDamagesWithLabels(activity_id, callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: '_design',
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			var damages = response.rows.filter((damage) => {
				return damage.doc.activity.id === activity_id;
			});
			const label = [
				'non definito',
				'buono',
				'mediocre',
				'critico',
				'Aperto',
				'Assegnato',
				'Completato'
			];
			const color = [
				'text-color-gray',
				'text-color-green',
				'text-color-yellow',
				'text-color-red',
				'text-color-gray',
				'text-color-blue',
				'text-color-green'
			];
			damages.forEach((damage)=>{
				damage.doc.colorstat = color[damage.doc.status];
				damage.doc.colorfixstat = color[damage.doc.fix_status + 4];
				damage.doc.labelstat = label[damage.doc.status];
				damage.doc.labelfixstat = label[damage.doc.fix_status + 4];
			});
			return callback(undefined, damages);
		}
	});
}

export function getDamagesWithLabelsByTimeRange(activity_id, start, end, callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: start.toISOString(),
		endkey: end.toISOString(),
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			var damages = response.rows.filter((damage) => {
				return damage.doc.activity.id === activity_id;
			});
			const label = [
				'non definito',
				'buono',
				'mediocre',
				'critico',
				'Aperto',
				'Assegnato',
				'Completato'
			];
			const color = [
				'text-color-gray',
				'text-color-green',
				'text-color-yellow',
				'text-color-red',
				'text-color-gray',
				'text-color-blue',
				'text-color-green'
			];
			damages.forEach((damage)=>{
				damage.doc.colorstat = color[damage.doc.status];
				damage.doc.colorfixstat = color[damage.doc.fix_status + 4];
				damage.doc.labelstat = label[damage.doc.status];
				damage.doc.labelfixstat = label[damage.doc.fix_status + 4];
			});
			return callback(undefined, damages);
		}
	});
}

export function getAllActivitiesWithDamages(callback) {
	getAllActivities((error, activities) => {
		if(error) {
			return callback(error, undefined);
		}
		getAllDamages((error, damages) => {
			if (error) {
				return callback(error, undefined);
			}
			if (activities.length === 0) {
				return callback(undefined, []);
			}
			activities.forEach((activity) => {
				activity.doc.damages = [];
				damages.forEach((damage) => {
					if (activity.id === damage.doc.activity.id) {
						activity.doc.damages.unshift(damage);
					}
				});
			});
			return callback(undefined, activities);
		});
	});
}

function getAllActivitiesByTimeRange(start, end, callback) {
	remoteDBactivities.allDocs({
		include_docs: true,
		startkey: start.toISOString(),
		endkey: end.toISOString(),
		descending: true
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		} else {
			return callback(undefined, response.rows);
		}
	});
}

function getAllDamagesByTimeRange(start, end, callback) {
	remoteDBdamages.allDocs({
		include_docs: true,
		startkey: start.toISOString(),
		emdkey: end.toISOString(),
		descending: true
	}, (error, response) => {
		if (error) {
			return callback(error, undefined);
		} else {
			return callback(undefined, response.rows);
		}
	});
}

export function getAllActivitiesWithDamagesByTimeRange(start, end, callback) {
	getAllActivitiesByTimeRange(start, end, (error, activities) => {
		if(error) {
			return callback(error, undefined);
		}
		if (activities.length === 0) {
			return callback(undefined, []);
		}
		getAllDamagesByTimeRange(start, end, (error, damages) => {
			if (error) {
				return callback(error, undefined);
			}
			activities.forEach((activity) => {
				activity.doc.damages = [];
				activity.doc.open_damages = false;
				damages.forEach((damage) => {
					if (activity.id === damage.doc.activity.id) {
						activity.doc.damages.unshift(damage);
						if(damage.doc.fix_status !== 2) {
							activity.doc.open_damages = true;
						}
					}
				});
			});
			return callback(undefined, activities);
		});
	});
}

function getActivitiesByTimeRange(route_id, start, end, callback) {
	remoteDBactivities.allDocs({
		include_docs: true,
		startkey: start.toISOString(),
		endkey: end.toISOString(),
		descending: true
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		var activities = response.rows.filter( (activity) => {
			return activity.doc.route.id === route_id;
		});
		return callback(undefined, activities);
	});
}

export function getActivitiesWithDamagesByTimeRange(route_id, start, end, callback) {
	getActivitiesByTimeRange(route_id, start, end, (error, activities) => {
		if(error) {
			return callback(error, undefined);
		}
		if (activities.length === 0) {
			return callback(undefined, []);
		}
		getAllDamagesByTimeRange(start, end, (error, damages) => {
			if (error) {
				return callback(error, undefined);
			}

			activities.forEach((activity) => {
				activity.doc.damages = [];
				activity.doc.open_damages = false;
				damages.forEach((damage) => {
					if (activity.id === damage.doc.activity.id) {
						activity.doc.damages.unshift(damage);
						if(damage.doc.fix_status !== 2) {
							activity.doc.open_damages = true;
						}
					}
				});
			});
			return callback(undefined, activities);
		});
	});
}
