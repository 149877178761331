import {app} from '../app';
import PouchDB from 'pouchdb-browser';
import PouchDBAuth from 'pouchdb-authentication';
PouchDB.plugin(PouchDBAuth);

var uuid = require('uuid');

const couch_address = 'https://gestioneintegrataservizi.it:3395/';
const api_url = 'https://gestioneintegrataservizi.it:3391/';
// const api_url = 'http://192.168.118.60:3030/';

var userDB = new PouchDB(couch_address, {skip_setup: true});

// Get a list of all users
export function getAllUsers(callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		app.request({
			url: api_url+'users',
			method: 'GET',
			dataType: 'json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Get a user
export function getUser(username, callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		app.request({
			url: api_url+'user/'+username,
			method: 'GET',
			dataType: 'json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Create a new user
export function putNewUser(userData, callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		app.request({
			url: api_url+'newUser',
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			data: userData,
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Update a user
export function putUpdateUser(userData, callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		app.request({
			url: api_url+'updateUser',
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			data: userData,
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Delete a user
export function deleteUser(username, callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (error, response) => {
		if(error) {
			return callback(error, undefined);
		}
		app.request({
			url: api_url+'user/'+username,
			method: 'DELETE',
			dataType: 'json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}
