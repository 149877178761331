import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Template7 from 'template7';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

import '../css/my-app.css';
import '../css/login.css';
import '../css/panel.css';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

export var app = new Framework7({
	root: '#app', // App root element
	component: App, // App main component

	name: 'DrEAM Dashboard', // App name
	theme: 'auto', // Automatic theme detection

	// App routes
	routes: routes,

	data: function() {
		return {
			user: {},
			version: {
				number: '2.1.1'
			}
		};
	},

	serviceWorker: {
		path: '/service-worker.js',
	},
});
