
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { openMap } from '../js/modules/my_fun.js';
import { getActivitiesByTimeRange } from '../js/modules/couchdb-infinite-module';
export default {
  methods: {
    openMap(lat, lon) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      openMap(lat, lon);
    },

    openRouteLink(route_id, route_name) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$app.views.main.router.navigate({
        url: '/route-detail-page/' + route_id + '/' + route_name + '/'
      });
    },

    openActivity(activity_id, activity_name) {
      activity_name = activity_name.replaceAll('/', '-');
      var url = "/activity-detail-page/" + activity_id + "/'" + activity_name + "'/";
      this.$app.views.main.router.navigate(url);
    }

  },
  on: {
    pageInit: function (page) {
      var searchbarAct = this.$app.searchbar.create({
        el: '.search-activity',
        searchContainer: '.searchbar-found-act',
        searchIn: ['.item-search-activity']
      });
      /*
       *	Infinite scroll
       */

      var self = this;

      if (this.activities.length < 15) {
        if (this.activities.length === 0) {
          this.$('.searchbar-not-found').css('display', 'block');
        }

        this.$('.infinite-scroll-preloader').css('display', 'none');
      } else {
        this.$('.infinite-scroll-preloader').css('display', 'block');
      }

      var allow_infinite = true;
      var last_time = this.last_time;
      this.$('#activities-scroll').on('infinite', function () {
        if (!allow_infinite) return;
        allow_infinite = false;

        if (self.linkBack) {
          var id = self.route_id;
          var start_time = last_time;
          var end_time = new Date(last_time.getFullYear(), last_time.getMonth(), last_time.getDate() - 7);
        } else {
          var id = undefined;
          var start_time = last_time;
          var end_time = new Date(last_time.getFullYear(), last_time.getMonth(), last_time.getDate() - 1);
        }

        getActivitiesByTimeRange(id, start_time, end_time, (error, response) => {
          if (error) {
            self.$app.dialog.alert('Non riesco a caricare altre attività.<br>Riprova più tardi.', () => {
              self.$router.refreshPage();
            });
          } else {
            if (response.length === 0) {
              if (end_time <= new Date(2021, 0, 1)) {
                self.$('.infinite-scroll-preloader').css('display', 'none');
                return;
              }

              last_time = end_time;
              allow_infinite = true;
              self.$('#activities-scroll').trigger('infinite');
            } else {
              self.activities = self.activities.concat(response);
              self.$('.searchbar-not-found').css('display', 'none');

              if (self.activities.length > 10) {
                self.$('.infinite-scroll-preloader').css('display', 'block');
              }

              self.$setState();

              if (end_time <= new Date(2021, 0, 1)) {
                self.$('.infinite-scroll-preloader').css('display', 'none');
                return;
              }

              last_time = end_time;
              allow_infinite = true;

              if (self.activities.length < 10) {
                self.$('#activities-scroll').trigger('infinite');
              }
            }
          }
        });
      });

      if (this.activities.length <= 10) {
        this.$('#activities-scroll').trigger('infinite');
      }
    }
  },
  id: '4a8b850796',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=activity-page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.linkBack, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only back" data-ignore-cache=true data-force=true><i class="icon f7-icons">arrow_left</i> </a>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only panel-open"><i class="icon f7-icons">menu</i> </a>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <a href=/ class="link icon-only" data-ignore-cache=true data-force=true><i class="icon f7-icons">house</i></a></div><div class=title>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.linkBack, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' Attività su ';
          r += c(ctx_2.route_name, ctx_2);
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' Lista Attività ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div><div class=subnavbar><form class="searchbar search-activity"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca Attività"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div><div class="page-content infinite-scroll-content" id=activities-scroll><!-- Searchbar backdrop --><div class=searchbar-backdrop></div><div class="list media-list searchbar-found-act"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.activities, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=# class="item-content item-link" @click="openActivity(\'';
          r += c(ctx_2.id, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.time.start, ctx_2);
          r += '\')"><div class=item-inner><div class=item-title-row><div class="item-title item-search-activity"><p class="text-color-white item-search-activity">Attività del ';
          r += Template7Helpers.js.call(ctx_2, "this.doc.time.start.split(' ')[0]", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p><p class=item-search-activity hidden>';
          r += c(ctx_2.id, ctx_2);
          r += '</p><p class="gpstxt item-search-activity" @click="openRouteLink(\'';
          r += c(ctx_2.doc.route.id, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.route.name, ctx_2);
          r += '\')">Itinerario: <span class="text-color-yellow item-search-activity">';
          r += c(ctx_2.doc.route.name, ctx_2);
          r += '</span></p></div><div class=item-after>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.doc.damages.length === 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <i class="act_icon icon f7-icons color-green tooltip-init" data-tooltip="Nessuna criticità">location_circle </i>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.open_damages', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.open_damages', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <i class="act_icon icon f7-icons color-red tooltip-init" data-tooltip="Presenti criticità aperte o assegnate">location_circle </i>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <i class="act_icon icon f7-icons color-green tooltip-init" data-tooltip="Tutte le criticità completate">location_circle </i>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class="item-subtitle item-search-activity text-color-white">Numero di criticità rilevate: <span class=text-color-blue><b>';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.damages.length', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</b></span></div><div class=item-text><span>Punto iniziale: <i class="icon f7-icons tooltip-init text-color-yellow" data-tooltip="Apri mappa!" @click="openMap(\'';
          r += c(ctx_2.doc.start_gps.gps_lat, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.start_gps.gps_lon, ctx_2);
          r += '\')">map_pin_ellipse </i></span>';
          r += ' <span>Punto finale: <i class="icon f7-icons tooltip-init text-color-yellow" data-tooltip="Apri mappa!" @click="openMap(\'';
          r += c(ctx_2.doc.end_gps.gps_lat, ctx_2);
          r += '\', \'';
          r += c(ctx_2.doc.end_gps.gps_lon, ctx_2);
          r += '\')">map_pin_ellipse </i></span>';
          r += '</div><div class="item-text item-search-activity">Iniziata da ';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.user.firstname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.user.familyname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' il ';
          r += c(ctx_2.doc.time.start, ctx_2);
          r += '</div>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.doc.time.end, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="item-text item-search-activity">Chiusa il ';
              r += c(ctx_3.doc.time.end, ctx_3);
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="list media-list searchbar-not-found"><ul><li><a href=# class=item-content><div class=item-inner><div class=item-title-row><div class="item-title text-color-yellow"><p>Nessuna Attività Trovata</p></div></div></div></a></li></ul></div><div class="preloader infinite-scroll-preloader"></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    