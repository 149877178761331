import PouchDB from 'pouchdb-browser';
import PouchDBFind from 'pouchdb-find';
PouchDB.plugin(PouchDBFind);

var couch_address = 'https://gestioneintegrataservizi.it:3395';

export var remoteDBusers = new PouchDB(couch_address + '/dream_users-md5/', {skip_setup: true});

export var remoteDBactivities = new PouchDB(couch_address + '/dream_activities/', {skip_setup: true});

// export var remoteDBdamages = new PouchDB(couch_address + '/dream-test/', {skip_setup: true});
export var remoteDBdamages = new PouchDB(couch_address + '/dream/', {skip_setup: true});

export var remoteDBroutes = new PouchDB(couch_address + '/dream_routelist/', {skip_setup: true});

export var remoteDB_users = new PouchDB(couch_address + '/_users/', {skip_setup: true});

export function checkLogin(username, password, self){
	console.log('login requested');
	remoteDBusers.find({
		selector: {
			username: username,
			password: password
		},
	}).then((result)=>{
		if (result.docs.length) {
			self.$app.data.user = result.docs[0];
			delete self.$app.data.user.password;
			self.$app.data.user.loggedIn = true;
			sessionStorage.setItem('userdata',JSON.stringify(self.$app.data.user));
			self.$router.navigate('/', {reloadCurrent: true});
			self.$app.panel.get('.panel-left').toggle();
		}else{
			self.$app.dialog.alert('Username e/o Password non validi. Riprova.','DrEAM problema di login');
		}
	});
}


export function getAllUsers(callback) {
	remoteDBusers.allDocs({include_docs: true, endkey: '_design'}, (err, res)=>{
		if (!err){
			callback(res.rows);
		}
	});
}

export function getAllRoutes(callback) {
	remoteDBroutes.allDocs({include_docs: true, endkey: '_design'}, (err, res)=>{
		if (!err){
			callback(res.rows);
		}
	});
}

export function getAllActivities(activity_id = {$gte: null}, route_id = {$gte: null}, callback) {
	remoteDBdamages.find(
		{
			selector: {
				route_id: route_id,
				activity_id: activity_id
			}
		},
		(err, resDmg)=>{
		if (!err){
			var damages = resDmg.docs;
			remoteDBusers.find(
			{
				selector: {
					username: {$gte: null},
				},
				fields: ['_id', 'username']
			},
			(err, userdata)=>{
				remoteDBroutes.find(
				{
					selector: {
						_id: route_id,
					},
					fields: ['_id', 'name']
				},
				(err, routedata)=>{
					if (!err){
						remoteDBactivities.find(
						{
							selector: {
								_id: activity_id,
								route_id: route_id,
							},
						}, (err, res)=>{
							if (!err){
								if (res.docs.length){
									res.docs.forEach((doc)=>{
										var found_route_id = routedata.docs.find(el => el._id == doc.route_id);
										if(found_route_id) {
											doc.route_name = found_route_id.name;
										}
										//doc.doc.route_name = routedata.docs[0].name;
										doc.user_id = userdata.docs.find(el => el._id == doc.user_id).username;
										doc.dmgCount = damages.reduce((n, x) => n + (x.activity_id == doc._id), 0);
									});
								}else{
									if (typeof route_id === 'object') {
										res.docs = [{route_name: ''}];
									} else {
										res.docs = [{route_name: routedata.docs.find(el => el._id == route_id).name}];
									}
								}
								callback(res.docs);
							}
						});
					}
				});
			});
		}
	});
}

export function getAllDamages(activity_id = {$gte: null}, callback) {
	remoteDBusers.find(
	{
		selector: {
			_id: {$gte: null},
		},
		fields: ['_id', 'username']
	},
	(err, userdata)=>{
		if (!err){
			remoteDBdamages.find(
				{
					selector: {
						activity_id: activity_id
					}
				},
				(err, res)=>{
				if (!err){
					if (res.docs.length){
						res.docs.forEach((doc)=>{
							doc.user_id = userdata.docs.find(el => el._id == doc.user_id).username;
						})
					}else{
						res.docs = [{activity_id: activity_id}];
					}
					callback(res.docs);
				}
			});
		}
	});
}

export function getDamage(id, callback) {
	remoteDBdamages.get(id, {attachments: true}, (err, res)=>{
		if (!err){
			remoteDBusers.get(res.user_id, (err, user)=>{
				res.username = user.username;
				res.firstname = user.firstname;
				res.familyname = user.familyname;
				res.level = user.level;
				callback(res);
			});
		}
	});
}


export function checkUsername(username, callback){
	remoteDBusers.find(
		{
			selector: {
				username: username,
			},
			fields: ['username']
		},
		(err, response)=> {
			if (err) {
				callback(err)
			}else{
				callback(response.docs);
			}
		}
	);
}

export function checkRouteId(callback){
	remoteDBroutes.allDocs({
		endkey: '_design'
	}, (err, res)=>{
		if (!err){
			let arr = [];
			res.rows.forEach(doc => arr.push(Number(doc.id.split('R')[1])));
			let newid = 'R' + (Math.max(...arr) + 1).toString().padStart(3,'0');
			callback(newid);
		}
	});
}
/*
export function getAllProcess(callback) {
	remoteDBprocess.allDocs(
		{
			include_docs: true,
		},
		function(err, response) {
			if (err) {
				console.log(err);
				callback(err);
			};
			//console.log(response);
			response.rows.forEach((el, index)=>{
				if (el.id.slice(0,7) == "_design"){
					response.rows.splice(index,1);
				}
			});
			callback(response);
		}
	);
}

export function getAllProcessSorted(field, sorting, db_fields, callback) {
	remoteDBprocess.find(
		{
			selector: {
				[field]: {$gte: null},
			},
			fields: db_fields,
			sort: [{[field]: sorting}]
		},
		function(err, response) {
			if (err) {
				console.log(err);
				callback(err);
			};
			callback(response);
		}
	);
}

export function getUserDBs(users, db_fields, callback) {
	let query1 = [];
	let finalResponse = {};
	remoteDBgs.find(
		{
			selector: {
				creator: {$or: users},
				visibility: 'private',
			},
			fields: db_fields
		},
		function(err, response) {
			if (err) {
				console.log(err);
				callback(err);
			};
			query1 = response.docs;
			//second query on type 'public'
			remoteDBgs.find(
				{
					selector: {
						visibility: 'public',
					},
					fields: db_fields
				},
				function(err, pubres) {
					if (err) {
						console.log(err);
						callback(err);
					};
					// final concatenated Output = 'my and my children private' + 'all public'

					finalResponse.gs = query1.concat(pubres.docs);

					remoteDBmission.find(
						{
							selector: {
								creator: {$or: users},
								visibility: 'private',
							},
							fields: db_fields
						},
						function(err, response) {
							if (err) {
								console.log(err);
								callback(err);
							};
							query1 = response.docs;
							//second query on type 'public'
							remoteDBmission.find(
								{
									selector: {
										visibility: 'public',
									},
									fields: db_fields
								},
								function(err, pubres) {
									if (err) {
										console.log(err);
										callback(err);
									};
									// final concatenated Output = 'my and my children private' + 'all public'
									finalResponse.mission = query1.concat(pubres.docs);

									remoteDBpropagation.find(
										{
											selector: {
												creator: {$or: users},
												visibility: 'private',
											},
											fields: db_fields
										},
										function(err, response) {
											if (err) {
												console.log(err);
												callback(err);
											};
											query1 = response.docs;
											//second query on type 'public'
											remoteDBpropagation.find(
												{
													selector: {
														visibility: 'public',
													},
													fields: db_fields
												},
												function(err, pubres) {
													if (err) {
														console.log(err);
														callback(err);
													};
													// final concatenated Output = 'my and my children private' + 'all public'
													finalResponse.propagation = query1.concat(pubres.docs);
													callback(finalResponse);
												}
											);
										}
									);
								}
							);
						}
					);
				}
			);
		}
	);

}

export function getUserProcess(users, db_fields, callback) {
	let query1 = [];
	let query2 = [];
	//first query on type = 'private' (my and my children only)
	remoteDBprocess.find(
		{
			selector: {
				email: {$or: users},
				type: 'private',
			},
			fields: db_fields
		},
		function(err, response) {
			if (err) {
				console.log(err);
				callback(err);
			};
			query1 = response.docs;
			//second query on type 'public' and 'shared' (all)
			remoteDBprocess.find(
				{
					selector: {
						type: {$or: ['public', 'shared']},
					},
					fields: db_fields
				},
				function(err, response) {
					if (err) {
						console.log(err);
						callback(err);
					};
					var resPublicOnly = response.docs.filter(obj => {
						return obj.type === 'public';
					});
					//filter loop on type = 'shared'
					var resSharedOnly = response.docs.filter(obj => {
						return obj.type === 'shared';
					});
					//filter loop on visibility field (all users VS all visibility)
					resSharedOnly.forEach(function(elObj){
						let checkUser = function(UserEle) {return UserEle == this};
						let checkVis = (VisEle) => users.some(checkUser, VisEle);
						if (elObj.visibility.some(checkVis)) {
							query2.push(elObj);
						}
					});
					// final concatenated Output = 'my and my children private' + 'all public' + 'shared with me and my children'

					callback(query2.concat(query1).concat(resPublicOnly));
				}
			);

		}
	);

}


export function getAllAdmins(callback) {
	remoteDBusers.find(
		{
			selector: {
				level: 'admin',
			},
			fields: ['_id', 'email'],
		},
		function(err, response) {
			if (err) {
				console.log(err);
				callback(err);
			};
			callback(response);
		}
	);
}

export function removeUserParents(email, callback){
	remoteDBusers.find(
		{
			selector: {
				parent: email,
			}
		},
		function(err, doc) {
			if (err) {
				console.log(err);
				callback(err);
			};
			if (doc.docs.length != 0){
				doc.docs.forEach(function(elem){
					//console.log(elem);
					elem.parent = "";
					remoteDBusers.put(elem, function(err, res){
						if (err) {
							callback(err);
						}else{
							callback(res);
						}
					});
				});
			}else{
				callback('ok');
			}
		}
	);

}



export function getAllChildUsers(email, level, callback) {
	let queryOutput = [];
	if (level == "admin"){
		remoteDBusers.find(
			{
				selector: {
					parent: email,
				},
				fields: ['email']
			},
			function(err, response) {
				if (err) {
					console.log(err);
					callback(err);
				};
				response.docs.forEach(function(el, idx){
					queryOutput[idx] = el.email;
				});
				queryOutput.push(email);
				callback(queryOutput);
			}
		);
	}else if(level == 'standard' || level == 'operational'){
		callback([email]);
	}
}

export function storeProcess(obj, tabs, email, emailcontact, linkStr, progress, _id, _rev, self, callback){

	let d = new Date();
	let hexString = ''; //to be read from DB
	let newestID = ''; // from hex string to decimal number
	let processDoc = {};

	if (_id == ""){
		remoteDBprocess.find(
			{
				selector: {_id: {$gte: null}},
				fields: ['jobid', '_id'],
				sort: [{'_id': 'desc'}]
			},
			function(err, response) {
				if (err) {
					console.log(err);
					callback('error');
				};
				//console.log(response);
				if (response.docs.length > 0){
					hexString = response.docs[0].jobid.slice(0,6);
				}else{
					hexString = '000000';
				}
				newestID = parseInt(hexString, 16);

				processDoc._id = "0" + Date.now();
				processDoc.name = obj.jobname;
				processDoc.date = d.getDate().toString().padStart(2,'0') + '/' + (d.getMonth()+1).toString().padStart(2,'0') + '/' + (d.getYear() + 1900).toString() + ' ' + d.getHours().toString().padStart(2,'0') + ':' + d.getMinutes().toString().padStart(2,'0') + ':' + d.getSeconds().toString().padStart(2,'0');
				processDoc.email = email;
				processDoc.emailcontact = emailcontact;
				processDoc.jobid = (newestID+1).toString(16).toUpperCase().padStart(6,'0') + '-' + obj.mode.slice(0,2).toUpperCase(); // + (Array.isArray(obj.linkid) ? 'SL' : 'ML');
				processDoc.links = linkStr;
				processDoc.mode = obj.mode;
				processDoc.form = obj.form;
				processDoc.type = "private";
				processDoc.visibility = [email];
				processDoc.progress = progress;
				processDoc.locked = false;
				processDoc.input = obj;
				processDoc.input.jobid = processDoc.jobid;
				processDoc.output = {};
				processDoc.tabs = tabs;

				remoteDBprocess.put(processDoc, function(err, res){
					if (err) {
						callback('error');
					}else{
						callback({_id: res.id, _rev: res.rev, jobid: processDoc.jobid});
					}
				});
			}
		);
	}else{

		remoteDBprocess.get(_id, function(err, response){
			if (err) {
				console.log(err);
				callback('errorexist');
			};
			processDoc = response;
			processDoc._id = _id;
			processDoc.name = obj.jobname;
			processDoc.date = d.getDate().toString().padStart(2,'0') + '/' + (d.getMonth()+1).toString().padStart(2,'0') + '/' + (d.getYear() + 1900).toString() + ' ' + d.getHours().toString().padStart(2,'0') + ':' + d.getMinutes().toString().padStart(2,'0') + ':' + d.getSeconds().toString().padStart(2,'0');
			processDoc.links = linkStr;
			processDoc.input = obj;
			processDoc.tabs = tabs;
			processDoc.progress = progress;
			processDoc.output = {};
			delete processDoc._attachments;
			let txt = '';
			if (processDoc.form != obj.form){
				if (obj.form == 'engineering'){
					txt = 'You are about to overwrite this job [id: '+ processDoc.jobid +'] with the customisation applied in this engineering form. Do you want to proceed?';
				}else if(obj.form == 'standard'){
					txt = 'You are about to overwrite this job [id: '+ processDoc.jobid +'] which contains customisation previously applied with an engineering form. If continue all customisations will be lost. Do you want to proceed?';
				}
			}else{
				txt = 'You are about to overwrite this job [id: '+ processDoc.jobid +']. Do you want to proceed?';
			};
			self.$app.preloader.hide();
			self.$app.dialog.confirm(txt, ()=>{
				self.$app.preloader.show();
				processDoc.form = obj.form;
				remoteDBprocess.put(
					processDoc,
					function(err, res) {
						if (err) {
							console.log(err);
							callback(err);
						}else{
							//console.log(res)
							callback({_id: res.id, _rev: res.rev, jobid: processDoc.jobid});
						}
					}
				);
			});
		});

	}
}

export function toggleLock(id, callback){
	remoteDBprocess.get(id, function(err, res){
		if (err) {
			callback(err);
		}else{
			res.locked = !res.locked;
			remoteDBprocess.put(res, function(err, response){
				if (err) {
					console.log(err);
					callback(err);
				}else{
					callback(response);
				}
			});
		}
	});
}

export function setToPublic(id, callback){
	remoteDBprocess.get(id, function(err, res){
		if (err) {
			callback(err);
		}else{
			res.type = 'public';
			remoteDBprocess.put(res, function(err, response){
				if (err) {
					console.log(err);
					callback(err);
				}else{
					callback(response);
				}
			});
		}
	});
}

export function duplicateProcess(id, callback){
	remoteDBprocess.get(id, function(err, res){
		if (err) {
			callback(err);
		}else{
			storeProcess(res.input, res.tabs, res.email, res.emailcontact, res.links, res.progress, "", "", "", (response)=>callback(response));
		}
	});
}

*/
